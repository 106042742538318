import React from 'react'
const isBrowser = typeof window !== 'undefined'

const RichTextEditor = React.lazy(() => import('react-rte'))

const Editor = (props) => {
  if (isBrowser)
    return (
      <>
        <React.Suspense fallback={<div />}>
          <RichTextEditor {...props} />
        </React.Suspense>
      </>
    )
  return null
}

export default Editor
